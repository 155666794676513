<template>
  <div class="cameraall">
    <div class="camera">
      <div class="camera_left">
        <!-- <div class="record" v-show="!iscamera">
         <img src="@/assets/head.png" class="record_head">
        </div>
        <div class="record" v-show="!iscamera">
          <img src="@/assets/head.png" class="record_head">
         </div> -->
        <img src="@/assets/record.png" class="record2" v-show="!iscamera">
        <img src="@/assets/record.png" class="record2" v-show="!iscamera">
        <video ref="camera" autoplay class="record2" v-show="iscamera"></video>
        <video ref="camera1" autoplay class="record2" v-show="iscamera"></video>
      </div>
      <div class="camera_show"  v-if="capturesuccess">
        <img :src="headimg" class="camera_img">
      </div>

    </div>
    <div class="camera_verification">
      <div class="photograph_bto">
        <div>
          <div class="photograph" @click="openCamera" v-if="!iscamera">开启摄像头</div>
          <div class="photograph" @click="capture" v-else>拍照</div>
        </div>
      </div>
      <div class="photograph" @click="verification" v-if="capturesuccess">举牌稽核</div>
    </div>
    <!-- <div class="photograph" @click="openCamera" v-if="!iscamera">开启摄像头</div>
    <div v-else class="photograph_all">
      <div class="photograph" @click="capture">拍照</div>
      <div class="photograph" @click="verification" v-if="capturesuccess">举牌稽核</div>
    </div> -->
    <div class="verall" v-if="isResult">
      <div class="verheadsingle">
        <img :src="result.photoHead" class="verhead">
        <div class="headtitle">人脸截图</div>
      </div>
      <div>
        <div class="versingle">
          <img src="@/assets/ver2.png" class="vertitle">
          <div class="verinput">{{result.ocrStringOne}}</div>
        </div>
        <div class="versingle">
          <img src="@/assets/ver3.png" class="vertitle">
          <div class="verinput">{{result.ocrStringTwo}}</div>
        </div>
      </div>
    </div>
    <canvas ref="canvas1" width="186px" height="140px" style="display:none"></canvas>
    <canvas ref="canvas2" width="186px" height="140px" style="display:none"></canvas>
  </div>
</template>

<script>
  import axios from "axios";
  import GLOBAL from "../utils/common";
  export default {
    data() {
      return {
        headimg: '',  //彩色图片
        iscamera: false,  //是否摄像
        iscapture: false,  //拍照
        appId: "abcd", //appld
        userid: "mzx", //userid
        orderNo: '',  //订单号
        headBlob: null,  //彩色文件
        headBlob2: null,  //黑白文件
        isResult: false,// 是否有结果
        result: {},  //结果
        capturesuccess: false,  //是否拍照成功
      };
    },

    created() {
      // 随机生成一个随机数
      this.orderNo = this.getNum();
    },

    methods: {
      openCamera() {
        this.iscamera = true;
        navigator.mediaDevices.getUserMedia({ audio: true, video: true })

        navigator.mediaDevices.enumerateDevices()
          .then(this.gotDevices).catch(this.handleError);
      },
      gotDevices(deviceInfos) {
        var constraints = new Array(2);
        var id = 0;

        for (var i = 0; i !== deviceInfos.length; ++i) {
          var deviceInfo = deviceInfos[i];
          if (deviceInfo.kind === 'videoinput') {
            constraints[id] = {
              video: {
                deviceId: deviceInfo.deviceId,
                label: deviceInfo.label,
                width: { min: 1280 },
                height: { min: 960 }
              }
            };
            id = id + 1;
          }
        }

        if (window.stream) {
          window.stream.getTracks().forEach(function (track) {
            track.stop();
          });
        }


        var cidenx = constraints.findIndex((item) => {
          return item.video.label.indexOf('IR') > -1
        })


        var dindex = 0;
        if (cidenx == 0) {
          dindex = 1
        }

        navigator.mediaDevices.getUserMedia(constraints[cidenx]).
          then(this.gotStream).catch(this.handleError);
        navigator.mediaDevices.getUserMedia(constraints[dindex]).
          then(this.gotStream1).catch(this.handleError);

      },
      // 有色彩的
      gotStream(stream) {
        window.stream = stream; // make stream available to console
        this.$refs.camera.srcObject = stream;

        // 使用 tracking.js 在图像上框选出人脸
        // var canvas = document.getElementById('canvas');
        // this.drawBox(canvas, this.$refs.video);

      },
      // 没色彩的
      gotStream1(stream) {
        window.stream = stream; // make stream available to console
        this.$refs.camera1.srcObject = stream;

        // 相机2
        // var canvas1 = document.getElementById('canvas1');
        // this.drawBox(canvas1, videoElement1);
      },
      handleError(error) {
        console.log('Error: ', error);
      },
      // 拍照
      capture() {
        let canvas = this.$refs.canvas1;
        let canvas2 = this.$refs.canvas2;
        //获取 `canvas`元素，根据`srcVideo`中的数据进行图片绘制 `ctx.drawImage()`；
        let ctx = canvas.getContext('2d');
        let ctx2 = canvas2.getContext('2d');
        ctx.drawImage(this.$refs.camera, 0, 0, 160, 120);
        ctx2.drawImage(this.$refs.camera1, 0, 0, 160, 120);
        //将 `canvas`绘制的图片信息，展示在 `img`标签中；
        this.headimg = canvas.toDataURL();
        let black = canvas2.toDataURL();

        this.headBlob = this.dataURLtoFile(this.headimg, '彩色');
        this.headBlob2 = this.dataURLtoFile(black, '黑白');

        this.iscapture = true;
        this.capturesuccess = true;


        // const stream = this.$refs.camera.srcObject;
        // const tracks = stream.getTracks();
        // // 关闭摄像头和音频
        // tracks.forEach(track => {
        //   track.stop();
        // });

        // tracks2.forEach(track => {
        //   track.stop();
        // });
      },
      // base64转blob
      dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        var theBlob = new Blob([u8arr], { type: mime });
        theBlob.lastModifiedDate = new Date();
        theBlob.name = filename;
        return theBlob;
      },

      // 稽核
      verification() {
        const loading = this.$loading({
          lock: true,
          text: '稽核中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        const formData = new FormData();
        formData.append("sourcePhotoColor", this.headBlob);
        formData.append("sourcePhotoSecond", this.headBlob2);
        formData.append("orderNo", this.orderNo);
        formData.append("userId", this.userid);
        formData.append("version", "1.0.0");
        formData.append("appId", this.appId);


        axios.post(GLOBAL.BASE_URL + "server/h5/verification", formData, {
          "Content-type": "multipart/form-data",
        })
          .then(
            (res) => {
              loading.close();
              if (res.data.code == 0) {
                this.result = res.data.result;
                this.isResult = true;
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
              }
            },
            (err) => {
              // 出现错误时的处理
              this.$message({
                message: err.msg,
                type: "error",
              });
            }
          );
      },
      // 随机生成一个32位数
      getNum() {
        var chars = [
          "0",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "A",
          "B",
          "C",
          "D",
          "E",
          "F",
          "G",
          "H",
          "I",
          "J",
          "K",
          "L",
          "M",
          "N",
          "O",
          "P",
          "Q",
          "R",
          "S",
          "T",
          "U",
          "V",
          "W",
          "X",
          "Y",
          "Z",
          "a",
          "b",
          "c",
          "d",
          "e",
          "f",
          "g",
          "h",
          "i",
          "j",
          "k",
          "l",
          "m",
          "n",
          "o",
          "p",
          "q",
          "r",
          "s",
          "t",
          "u",
          "v",
          "w",
          "x",
          "y",
          "z",
        ];
        var nums = "";
        for (var i = 0; i < 32; i++) {
          var id = parseInt(Math.random() * 61);
          nums += chars[id];
        }
        return nums;
      },
    },
  };
</script>

<style scoped>
  .camera {
    margin: 14px auto 0;
    display: flex;
    justify-content: center;
    position: relative;
  }

  .camera_left {
    display: flex;
    width: 1349px;
    position: relative;
    justify-content: space-between;
  }

  .record {
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('/src/assets/record.png');
    background-size: 100% 100%;
    width: 620px;
    margin-top: 14px;
    height: 465px;
  }

  .record_head {
    width: 103px;
    height: 118px;
  }

  .record2 {
    width: 620px;
    height: 465px;
  }

  .camera_show {
    display: flex;
    position: absolute;
    top: 0;
    right: 100px;
    justify-content: flex-start;
  }

  .camera_img {
    width: 160px;
    height: 120px;
  }

  .photograph {
    background: url('/src/assets/button2.png');
    background-size: 100% 100%;
    width: 180px;
    height: 62px;
    text-align: center;
    line-height: 62px;
    margin-left: 10px;
    color: white;
    cursor: pointer;
  }

  .photograph_all {
    display: flex;
    align-items: center;
  }

  .vertitle {
    width: 198px;
    display: flex;
    justify-content: flex-end;
  }

  .verhead {
    width: 174px;
    height: 174px;
  }

  .versingle {
    display: flex;
    margin-top: 22px;
    align-items: center;
  }

  .verinput {
    width: 603px;
    height: 48px;
    line-height: 48px;
    text-align: left;
    padding-left: 10px;
    box-sizing: border-box;
    font-size: 20px;
    border: 1px solid #C1C9ED;
  }

  .photograph_bto {
    width: 620px;
    margin-left: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .camera_verification {
    width: 980px;
    margin: 20px auto 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .verall {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }

  .verheadsingle {
    margin-right: 57px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .headtitle {
    text-align: center;
    margin-top: 5px;
    font-size: 20px;
    color: #000;
    font-weight: bold;
  }
</style>