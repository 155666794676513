<template>
    <div>
      <div class="navsingle">
        核验结果</div>
      <div style="margin: 150px auto 0">
        <div class="result">
          <div class="result_single">
            <div class="result_title">姓名:</div>
            <div class="result_content">{{ livingbody.name }}</div>
          </div>
          <div class="result_single">
            <div class="result_title">身份证:</div>
            <div class="result_content">{{ livingbody.idNo }}</div>
          </div>
          <div class="result_single">
            <div class="result_title">活体认证得分:</div>
            <div class="result_content">{{ livingbody.liveRate }}</div>
          </div>
          <div class="result_single">
            <div class="result_title">人脸认证得分:</div>
            <div class="result_content">{{ livingbody.similarity }}</div>
          </div>
        </div>
      </div>
  
      <el-button type="primary" @click="goback">返回录制页面</el-button>
    </div>
  </template>
  
  <script>
    import http from "../utils/http";
    import sha1 from "sha1";
    export default {
      data() {
        return {
          livingbody: {}, //人脸检测信息
          appId: "abcd",
        };
      },
  
      created() {
        // 获取订单号
        var order = this.$route.query.orderNo;
  
        this.getAccesstoken(order);
      },
  
      methods: {
        // 获取Access Token
        getAccesstoken(order) {
          http.get("/api/oauth2/access_token", {
            app_id: this.appId,
            grant_type: "client_credential",
            secret: "1234",
            version: "1.0.0",
          }).then((res) => {
            if (res.code == 0) {
              this.getresult(res.access_token, order);
            }
          });
        },
        // 获取结果
        getresult(accesstoken, order) {
          http.get("/api/oauth2/api_ticket", {
            access_token: accesstoken,
            app_id: this.appId,
            type: "SIGN",
            version: "1.0.0",
          })
            .then((res) => {
              if (res.code == 0) {
                var none = this.getNum();
                var newsign = res.tickets[0].value;
  
                var test = [none, newsign, "1.0.0", this.appId, order];
  
                test.sort((s1, s2) => {
                  for (let i = 0; i < s1.length && i < s2.length; i++) {
                    if (s1[i] === s2[i]) {
                      if (i + 1 == s1.length || i + 1 == s2.length) {
                        return s1.length - s2.length;
                      }
                    } else if (s1[i] < s2[i]) return -1;
                    else if (s1[i] > s2[i]) return 1;
                  }
                });
  
                test = test.join("");
  
                var newsign = sha1(test).toUpperCase();
  
                http.get("/api/server/sync", {
                  app_id: this.appId,
                  get_file: "0",
                  get_photos: "null",
                  nonce: none,
                  order_no: order,
                  sign: newsign,
                  version: "1.0.0",
                }).then((res) => {
                  if (res.code == 0) {
                    this.livingbody = res.result;
                  } else {
                    this.$message.error("暂无此人脸结果");
                  }
                });
              }
            });
        },
  
        // 返回录制页面
        goback() {
          this.$router.replace({
            name: "hello",
          });
        },
  
        // 随机生成一个32位数
        getNum() {
          var chars = [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "A",
            "B",
            "C",
            "D",
            "E",
            "F",
            "G",
            "H",
            "I",
            "J",
            "K",
            "L",
            "M",
            "N",
            "O",
            "P",
            "Q",
            "R",
            "S",
            "T",
            "U",
            "V",
            "W",
            "X",
            "Y",
            "Z",
            "a",
            "b",
            "c",
            "d",
            "e",
            "f",
            "g",
            "h",
            "i",
            "j",
            "k",
            "l",
            "m",
            "n",
            "o",
            "p",
            "q",
            "r",
            "s",
            "t",
            "u",
            "v",
            "w",
            "x",
            "y",
            "z",
          ];
          var nums = "";
          for (var i = 0; i < 32; i++) {
            var id = parseInt(Math.random() * 61);
            nums += chars[id];
          }
          return nums;
        },
      },
    };
  </script>
  
  <style scoped>
    .ac {
      display: flex;
      width: 300px;
      justify-content: space-between;
      margin: 0 auto;
    }
  
    .navsingle {
      height: 78px;
      background: #FAFAFA;
      padding-left: 40px;
      font-size: 34px;
      line-height: 78px;
      color: #020202;
      text-align: left;
    }
    .result_title{
      width: 200px;
      font-size: 24px;
      margin-right: 20px;
      text-align: right;
    }
    .result_single{
      margin-bottom:10px ;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .result_content{
      width: 603px;
      height: 48px;
      line-height: 48px;
      padding-left: 24px;
      font-size: 22px;
      text-align: left;
      border: 1px solid #C1C9ED;
    }
  </style>