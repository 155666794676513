<template>
  <div class="result">
    <img src="@/assets/verifying.png" class="verifying" v-if="isshow == 0" />
    <div v-else-if="isshow == 1">
      <img src="@/assets/fail.png" class="fail" />
      <div class="fail_bto">
        <el-button type="primary" class="reset" @click="reset"
          >重新核验</el-button
        >
        <el-button type="warning" class="logout" @click="goback"
          >退出核验</el-button
        >
      </div>
    </div>
    <div v-else-if="isshow == 2">
      <img src="@/assets/success.png" class="fail" />
      <div class="fail_bto">
        <el-button type="primary" class="reset" @click="goback"
          >返回结果页</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import sha1 from "sha1";
import http from "../utils/http";
export default {
  data() {
    return {
      appId: "abcd",
      token: "", //token
      type: null, //类型
      isshow: 0,
      order: "",
    };
  },

  created() {
    if (window.parent.frames.location.href != window.location.href) {
      window.parent.frames.location.href = window.location.href;
    }
    
    // 获取订单号
    var order = this.$route.query.orderNo;
    this.type = this.$route.query.type;
    this.order = order;

    if (this.type == 1) {
      this.getAccesstoken();
    } else {
      this.getNewresult(order);
    }
  },

  methods: {
    // 获取Access Token
    getAccesstoken() {
      http
        .get("/api/oauth2/access_token", {
          app_id: this.appId,
          grant_type: "client_credential",
          secret: "1234",
          version: "1.0.0",
        })
        .then((res) => {
          if (res.code == 0) {
            this.getresult(res.access_token)
          }
        });
    },
    // 获取结果
    getresult(token) {
      var that = this;
      http
        .get("/api/oauth2/api_ticket", {
          access_token: token,
          app_id: that.appId,
          type: "SIGN",
          version: "1.0.0",
        })
        .then((res) => {
          if (res.code == 0) {
            var none = that.getNum();
            var newsign = res.tickets[0].value;

            var test = [none, newsign, "1.0.0", that.appId, that.order];

            test.sort((s1, s2) => {
              for (let i = 0; i < s1.length && i < s2.length; i++) {
                if (s1[i] === s2[i]) {
                  if (i + 1 == s1.length || i + 1 == s2.length) {
                    return s1.length - s2.length;
                  }
                } else if (s1[i] < s2[i]) return -1;
                else if (s1[i] > s2[i]) return 1;
              }
            });

            test = test.join("");

            var newsign = sha1(test).toUpperCase();

            http
              .get("/api/server/sync", {
                app_id: that.appId,
                get_file: "0",
                get_photos: "null",
                nonce: none,
                order_no: that.order,
                sign: newsign,
                version: "1.0.0",
              })
              .then((res) => {
                if (res.code == 0) {
                  that.isshow = 2;
                } else {
                  that.isshow = 1;
                }
              });
          }
        });
    },

    // 获取新结果
    getNewresult(order) {
      http
        .get("/api/server/liveliness/get_result", {
          orderNo: order,
        })
        .then((res) => {
          if (res.code == 0) {
            this.isshow = 2;
          } else {
            this.isshow = 1;
          }
        });
    },

    // 重新发起检验
    reset() {
      if (this.type == 1) {
        window.location.href =
          "https://web.face.third.srthinker.com/api/pc/again_login?orderNo=" +
          this.order;
      } else {
        window.location.href =
          "https://web.face.third.srthinker.com/api/pc/live/again_login?orderNo=" +
          this.order;
      }
    },
    // 返回录制页面
    goback() {
      var url = "";
      if (this.type == 1) {
        url =
          "https://web.face.third.srthinker.com/api/h5/still/redirect?orderNo=";
      } else {
        url =
          "https://web.face.third.srthinker.com/api/pc/live/redirect?orderNo=";
      }
      window.opener = null;
      window.open(url + this.order);
      window.close();
    },

    // 随机生成一个32位数
    getNum() {
      var chars = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
      ];
      var nums = "";
      for (var i = 0; i < 32; i++) {
        var id = parseInt(Math.random() * 61);
        nums += chars[id];
      }
      return nums;
    },
  },
};
</script>

<style  scoped>
.result {
  width: 100%;
  height: 100%;
  margin: 100px auto 0;
  position: relative;
}
.verifying {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
}
.fail {
  width: 300px;
}
.fail_bto {
  margin-top: 100px;
  display: flex;
  justify-content: center;
}
.logout {
  margin-left: 60px !important;
}
</style>