<template>
  <div>
    <img src="@/assets/record2.png" v-show="!isrecord" class="norecord" />
    <video
      ref="video"
      controls
      autoplay
      playsinline
      v-show="isrecord"
      class="norecord"
    ></video>
    <div class="selectcamera">
      <div style="margin-right: 15px">摄像头:</div>
      <el-select v-model="cameraValue" placeholder="请选择摄像头">
        <el-option
          v-for="item in cameraList"
          :key="item.label"
          :label="item.label"
          :value="item.label"
        >
        </el-option>
      </el-select>
    </div>
    <div class="openrecord" @click="startRecord">打开摄像头</div>
  </div>
</template>

<script>
import http from "../utils/http";
import RecordRTC from "recordrtc";
import axios from "axios";
import sha1 from "sha1";
import GLOBAL from "../utils/common";
export default {
  data() {
    return {
      isrecord: false, //是否开始录制
      cameraList: [], //摄像头列表
      cameraValue: null, //摄像头的值
      recordsuccess: false, //录制是否成功
      orderNo: "", //随机订单号
      order: "", //订单
      acctoken: "", //asstoken
      sign: "", //签名
      appId: "abcd", //appld
      userid: "mzx", //userid
    };
  },

  created() {
    // 获取token
    this.getAccesstoken();

    // 随机生成一个随机数
    this.orderNo = this.getNum();

    // 获取订单号
    this.order = this.$route.query.orderNo;

    // 获取本机摄像头列表
    this.getNumofcameras();
  },

  methods: {
    // 获取Access Token
    getAccesstoken() {
      http
        .get("/api/oauth2/access_token", {
          app_id: this.appId,
          grant_type: "client_credential",
          secret: "1234",
          version: "1.0.0",
        })
        .then((res) => {
          if (res.code == 0) {
            this.getApiticket(res.access_token);

            this.acctoken = res.access_token;
          }
        });
    },

    // 获取SIGN-NONCE ticket
    getApiticket(token) {
      http
        .get("/api/oauth2/api_ticket", {
          access_token: token,
          app_id: this.appId,
          type: "SIGN",
          // user_id: "11242423",
          version: "1.0.0",
        })
        .then((res) => {
          if (res.code == 0) {
            this.sign = res.tickets[0].value;
          }
        });
    },
    // 获取摄像头数量
    getNumofcameras() {
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        devices.map((item) => {
          if (item.kind == "videoinput") {
            this.cameraList.push({
              label:item.label,
              video: {
                deviceId: item.deviceId,
                label: item.label,
              },
            });
          }
        });
      });

      console.log(this.cameraList)
    },
    // 录制
    captureCamera(callback) {
      var index = this.cameraList.findIndex((item)=>item.label == this.cameraValue);
      navigator.mediaDevices
        ?.getUserMedia(this.cameraList[index])
        .then((camera) => {
          callback(camera);
        })
        .catch((error) => {
          alert("Unable to capture your camera. Please check console logs.");
          console.error(error);
        });
    },
    // 开始
    startRecord() {
      if (!this.cameraValue) {
        this.$message({
          message: "请先选择摄像头",
          type: "warning",
        });
        return;
      }
      this.isrecord = true;
      this.getAccesstoken(); //重新获取token
      const video = this.$refs.video;
      this.captureCamera((camera) => {
        video.muted = true;
        video.volume = 0;
        video.srcObject = camera;
        this.recorder = RecordRTC(camera, {
          type: "video",
        });
        this.recorder.startRecording();
        this.recorder.camera = camera;
      });

      setTimeout(() => {
        this.stopRecord();
      }, 5000);
    },
    // 停止
    stopRecord() {
      this.recorder.stopRecording(this.stopRecordingCallback);
    },
    // 停止的回调
    stopRecordingCallback() {
      const video = this.$refs.video;
      video.src = video.srcObject = null;
      video.muted = false;
      video.volume = 1;
      video.src = URL.createObjectURL(this.recorder.getBlob());

      const blob = this.recorder.getBlob();
      // 导出视频，遗憾的是这里windows自带播放器无法播放，chrome浏览器和手机上都可以播放，后面会讲到如何转码
      // RecordRTC.invokeSaveAsDialog(blob, 'test.mp4');

      this.recorder.camera.stop();
      this.recorder.destroy();
      this.recorder = null;

      this.hadleChange(blob);
    },
    // 上传视频
    hadleChange(blob) {
      var test = ["440221199609094538", "测试", this.userid, this.sign];

      const loading = this.$loading({
        lock: true,
        text: "上传视频中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      var newsign = this.generateSignature(test);

      const formData = new FormData();
      formData.append("videoFile", blob);
      formData.append("idNo", "440221199609094538");
      formData.append("name", "测试");
      formData.append("orderNo", this.orderNo);
      formData.append("userId", this.userid);
      formData.append("sign", newsign);
      formData.append("version", "1.0.0");
      formData.append("webankAppId", "abcd");
      axios
        .post(GLOBAL.BASE_URL + "server/h5/geth5faceid/video", formData, {
          "Content-type": "multipart/form-data",
        })
        .then(
          (res) => {
            loading.close();
            if (res.data.code == 0) {
              this.$router.push({
                name: "specialresult",
                query: {
                  orderNo: this.order,
                },
              });
            } else {
              switch (res.data.msg) {
                case "VIDEO_FACE_NOT_FOUND":
                  this.$message.error("上传的视频中没有检测到人脸");
                  break;
                case "VIDEO_LOW_FACE_QUALITY":
                  this.$message.error("上传的视频中人脸质量太差");
                  break;
                case "VIDEO_INVALID_DURATION":
                  this.$message.error("上传的视频时长不对");
                  break;
                case "VIDEO_FACE_OCCLUDE":
                  this.$message.error("上传的视频中人脸被遮挡");
                  break;
                case "VIDEO_UNSUPPORTED_FORMAT":
                  this.$message.error("视频无法解析");
                  break;
                case "VIDEO_NO_AUDIO":
                  this.$message.error("上传视频中没有音轨");
                  break;
                default:
                  break;
              }
            }
          },
          (err) => {
            // 出现错误时的处理
            this.$message({
              message: err.msg,
              type: "error",
            });
          }
        );
    },
    // base64编码
    getBase64(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader(); //定义方法读取文件
        reader.readAsDataURL(file); //开始读文件  本身是图片的二进制数据 进行base64加密形成字符串
        reader.onload = () => resolve(reader.result); //成功返回对应的值 reader.result可以直接放在img标签中使用
        reader.onerror = () => reject(error); //失败返回失败信息
      });
    },
    // 随机生成一个32位数
    getNum() {
      var chars = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
      ];
      var nums = "";
      for (var i = 0; i < 32; i++) {
        var id = parseInt(Math.random() * 61);
        nums += chars[id];
      }
      return nums;
    },
    // 生成签名
    generateSignature(value) {
      var newarr = [];
      var arr = ["1.0.0", this.appId, this.orderNo];

      newarr = [...arr, ...value];

      newarr.sort((s1, s2) => {
        for (let i = 0; i < s1.length && i < s2.length; i++) {
          if (s1[i] === s2[i]) {
            if (i + 1 == s1.length || i + 1 == s2.length) {
              return s1.length - s2.length;
            }
          } else if (s1[i] < s2[i]) return -1;
          else if (s1[i] > s2[i]) return 1;
        }
      });

      newarr = newarr.join("");

      return sha1(newarr).toUpperCase();
    },
  },
};
</script>

<style scoped>
.norecord {
  width: 818px;
  height: 505px;
  margin-top: 80px;
}

.openrecord {
  width: 180px;
  height: 62px;
  line-height: 62px;
  font-size: 24px;
  cursor: pointer;
  color: white;
  margin: 50px auto 0;
  text-align: center;
  background: url("/src/assets/button.png");
  background-size: 100% 100%;
}

.uploadimg {
  width: 818px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.uploadbto {
  width: 140px;
  height: 140px;
}

.upload_tips {
  font-size: 14px;
  color: #8b8b8b;
  margin-top: 8px;
}
.selectcamera {
  display: flex;
  align-items: center;
  font-size: 24px;
  margin-top: 20px;
  justify-content: center;
}
</style>