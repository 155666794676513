<template>
    <div>
        <!-- <div class="navtitie">
            <div v-for="item in navList" :class="['navsingle',currentIndex == item.index?'selectindex':'']"
                @click="switchPage(item)" :key="item.index">
                {{item.name}}</div>
        </div> -->
        <router-view />
    </div>
</template>

<script>
    import http from "../utils/http";
    import sha1 from "sha1";
    export default {
        data() {
            return {
                currentIndex: 0,
                navList: [{
                    name: '活体认证',
                    index: 0,
                    nav: 'vivoauthentication'
                }, {
                    name: '身份识别',
                    index: 1,
                    nav: 'identification'
                }, {
                    name: '举牌稽核',
                    index: 2,
                    nav: 'raiseplacard'
                }]
            };
        },

        watch: {
            $route: {
                handler(val, oldval) {
                    if (val) {
                        var item = this.navList.find((item) => item.nav == val.name);
                        if (item) {
                            this.navId = item.id;
                        }
                    }
                },
                // 深度观察监听
                deep: true,
            },
        },

        created() {
            var that = this;
            var name = that.$route.name;
            // 判断当前url在哪个路径下
            var item = that.navList.find((item) => item.nav == name);
            console.log(item)
            if (item) {
                that.currentIndex = item.index;
            }
        },

        methods: {
            // 切换页面
            switchPage(item) {
                this.currentIndex = item.index

                this.$router.push({
                    name: item.nav,
                });
            }
        },
    };
</script>

<style scoped>
    .navtitie {
        width: 100%;
        height: 78px;
        background: #FAFAFA;
        font-size: 34px;
        color: #020202;
        display: flex;
        align-items: center;
    }

    .navsingle {
        margin-left: 40px;
        cursor: pointer;
    }

    .selectindex {
        color: blue;
    }
</style>