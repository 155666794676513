<template>
  <div class="authentication_all">
    <div class="authentication">
      <div class="authentication_right">
        <div class="authentication_title">
          <div :class="['k', mode == 1 ? 'selectmode' : '']" @click="mode = 1">视频认证</div>
          <div :class="mode == 2 ? 'selectmode' : ''" style="cursor: pointer;" @click="mode = 2">双目认证</div>
        </div>
        <div v-show="mode == 1">
          <div class="binocular_photography">
            <img src="@/assets/record2.png" class="camera" v-show="!isopen">
            <video ref="video" controls autoplay playsinline class="camera" v-show="isopen"></video>
          </div>
          <div class="selectcamera">
            <el-select v-model="cameraValue" placeholder="请选择" style="margin-right: 15px;">
              <el-option v-for="item in cameraList" :key="item.label" :label="item.label" :value="item.label">
              </el-option>
            </el-select>
            <div class="authentication_open" @click="startRecord">打开摄像头</div>
          </div>
        </div>
        <div v-show="mode == 2">
          <div class="binocular_photography">
            <img src="@/assets/record2.png" class="camera" v-show="!isphotograph">
            <img src="@/assets/record2.png" class="camera" v-show="!isphotograph">
            <video ref="camera" autoplay class="camera" v-show="isphotograph"></video>
            <video ref="camera1" autoplay class="camera" v-show="isphotograph"></video>
          </div>
          <div class="authentication_open" @click="capture" v-if="isphotograph">确认</div>
        </div>
      </div>
    </div>

    <div @click="startLive">test</div>


    <canvas ref="canvas1" width="186px" height="140px" style="display:none"></canvas>
    <canvas ref="canvas2" width="186px" height="140px" style="display:none"></canvas>
  </div>
</template>

<script>
import http from "../utils/http";
import RecordRTC from "recordrtc";
import axios from "axios";
import sha1 from "sha1";
import GLOBAL from "../utils/common";
export default {
  data() {
    return {
      mode: 1,  //1是视频，2是双目拍照
      cameraList: [],  //摄像头数量
      cameraValue: null,  //摄像头信息
      isopen: false,  //是否打开摄像头
      isphotograph: false,  //是否拍照
      headimg: null,  //人脸图片
      headBlob: null, //彩色图片对比源
      headBlob2: null, //黑白图片对比源
      recordsuccess: false,  //录制是否成功
      uploadImg: null,  //是否上传图片
      idphoto: null,  //证件照
      orderNo: '',  //随机订单号
      acctoken: '',  //asstoken
      sign: '',  //签名
      appId: "", //appld
      userid: "abc", //userid
      backUrl: '',  //要跳转回去的url
    };
  },

  created() {
    // 随机生成一个随机数
    this.orderNo = this.getNum();

    if (this.$route.query.access_token) {
      this.acctoken = this.$route.query.access_token

      this.backUrl = this.$route.query.backurl
    }

    // 获取摄像头数量
    this.getNumofcameras()
  },

  watch: {
    mode: function (e) {
      if (e == 2) {
        if (this.cameraList.length != 2) {
          this.$message.error('摄像头数量不正确,无法打开')
          return;
        }

        this.isphotograph = true;
        // 切换到双目摄像头
        if (window.stream) {
          window.stream.getTracks().forEach(function (track) {
            track.stop();
          });
        }

        var cidenx = this.cameraList.findIndex((item) => {
          return item.label.indexOf('RGB') > -1
        })

        var dindex = 0;
        if (cidenx == 0) {
          dindex = 1
        }

        navigator.mediaDevices.getUserMedia(this.cameraList[cidenx]).
          then(this.gotStream).catch(this.handleError);
        navigator.mediaDevices.getUserMedia(this.cameraList[dindex]).
          then(this.gotStream1).catch(this.handleError);
      }
    }
  },

  methods: {

    // -------------------------------事前准备--------------------------------
    // 获取摄像头数量
    getNumofcameras() {
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        devices.map((item, index) => {
          if (item.kind == "videoinput") {
            if (item.label == '') {
              this.cameraList.push({
                label: 'camera' + index,
                audio: true,
                video: {
                  deviceId: item.deviceId,
                  label: item.label,
                },
              });
            } else {
              this.cameraList.push({
                label: item.label,
                audio: true,
                video: {
                  deviceId: item.deviceId,
                  label: item.label,
                },
              });
            }
          }
        });

        console.log(this.cameraList)
      });
    },


    // -------------------------------视频拍摄--------------------------------
    // 录制
    captureCamera(callback) {
      var index = this.cameraList.findIndex((item) => item.label == this.cameraValue);

      console.log(this.cameraList)
      navigator.mediaDevices
        ?.getUserMedia(this.cameraList[index])
        .then((camera) => {
          console.log(camera.getVideoTracks()[0].getSettings().frameRate)
          callback(camera);
        })
        .catch((error) => {
          alert("无法捕获您的相机,请检查后再试");
          console.error(error);
        });
    },
    // 开始
    startRecord() {
      if (!this.acctoken) {
        this.$message.error('请先获取access_token')
        return
      }
      if (!this.cameraValue) {
        this.$message.error('请先选择摄像头')
        return
      }

      this.isopen = true;
      const video = this.$refs.video;
      this.captureCamera((camera) => {
        video.muted = true;
        video.volume = 0;
        video.srcObject = camera;
        this.recorder = RecordRTC(camera, {
          type: "video",
        });
        this.recorder.startRecording();
        this.recorder.camera = camera;
      });

      setTimeout(() => {
        this.stopRecord();
      }, 5000);
    },
    // 停止
    stopRecord() {
      this.recorder.stopRecording(this.stopRecordingCallback);
    },
    // 停止的回调
    stopRecordingCallback() {
      const video = this.$refs.video;
      video.src = video.srcObject = null;
      video.muted = false;
      video.volume = 1;
      video.src = URL.createObjectURL(this.recorder.getBlob());

      const blob = this.recorder.getBlob();
      // 导出视频，遗憾的是这里windows自带播放器无法播放，chrome浏览器和手机上都可以播放，后面会讲到如何转码
      // RecordRTC.invokeSaveAsDialog(blob, 'test.mp4');


      this.recorder.camera.stop();
      this.recorder.destroy();
      this.recorder = null;

      this.hadleChange(blob)
    },
    // 上传视频
    hadleChange(blob) {
      var _this = this;

      const loading = _this.$loading({
        lock: true,
        text: "上传视频中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      const formData = new FormData();
      formData.append("file", blob);
      axios.post(GLOBAL.BASE_URL + "h5/video_info/upload", formData, {
        headers: {
          "Content-type": "multipart/form-data",
          "Authorization": "Bearer " + this.acctoken,
        }
      })
        .then(
          (res) => {
            loading.close();
            if (res.data.code == 0) {
              // window.location.href = this.backUrl + '?orderNo=' + res.data.result.orderNo;
            } else {
              switch (res.data.msg) {
                case "VIDEO_FACE_NOT_FOUND":
                  _this.$message.error("上传的视频中没有检测到人脸");
                  break;
                case "VIDEO_LOW_FACE_QUALITY":
                  _this.$message.error("上传的视频中人脸质量太差");
                  break;
                case "VIDEO_INVALID_DURATION":
                  _this.$message.error("上传的视频时长不对");
                  break;
                case "VIDEO_FACE_OCCLUDE":
                  _this.$message.error("上传的视频中人脸被遮挡");
                  break;
                case "VIDEO_UNSUPPORTED_FORMAT":
                  _this.$message.error("视频无法解析");
                  break;
                case "VIDEO_NO_AUDIO":
                  _this.$message.error("上传视频中没有音轨");
                  break;
                default:
                  _this.$message.error(res.data.message);
                  break;
              }
            }
          },
          (err) => {
            // 出现错误时的处理
            this.$message({
              message: err.msg,
              type: "error",
            });
          }
        );
    },
    // base64编码
    getBase64(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader(); //定义方法读取文件
        reader.readAsDataURL(file); //开始读文件  本身是图片的二进制数据 进行base64加密形成字符串
        reader.onload = () => resolve(reader.result); //成功返回对应的值 reader.result可以直接放在img标签中使用
        reader.onerror = () => reject(error); //失败返回失败信息
      });
    },
    // 随机生成一个32位数
    getNum() {
      var chars = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
      ];
      var nums = "";
      for (var i = 0; i < 32; i++) {
        var id = parseInt(Math.random() * 61);
        nums += chars[id];
      }
      return nums;
    },
    // 生成签名
    generateSignature(value) {
      var newarr = [];
      var arr = ["1.0.0", this.appId, this.orderNo];

      newarr = [...arr, ...value];

      newarr.sort((s1, s2) => {
        for (let i = 0; i < s1.length && i < s2.length; i++) {
          if (s1[i] === s2[i]) {
            if (i + 1 == s1.length || i + 1 == s2.length) {
              return s1.length - s2.length;
            }
          } else if (s1[i] < s2[i]) return -1;
          else if (s1[i] > s2[i]) return 1;
        }
      });

      newarr = newarr.join("");

      return sha1(newarr).toUpperCase();
    },


    // -------------------------------双目摄像头--------------------------------
    // 拍照
    capture() {
      if (!this.acctoken) {
        this.$message.error('请先获取access_token')
        return
      }

      this.clearVideo()

      let canvas = this.$refs.canvas1;
      let canvas2 = this.$refs.canvas2;
      //获取 `canvas`元素，根据`srcVideo`中的数据进行图片绘制 `ctx.drawImage()`；
      let ctx = canvas.getContext('2d');
      let ctx2 = canvas2.getContext('2d');
      ctx.drawImage(this.$refs.camera, 0, 0, 160, 120);
      ctx2.drawImage(this.$refs.camera1, 0, 0, 160, 120);
      //将 `canvas`绘制的图片信息，展示在 `img`标签中；
      this.headimg = canvas.toDataURL();
      let black = canvas2.toDataURL();

      this.headBlob = this.dataURLtoFile(this.headimg, '彩色');
      this.headBlob2 = this.dataURLtoFile(black, '黑白');


      this.verification()
      // const stream = this.$refs.camera.srcObject;
      // const tracks = stream.getTracks();
      // // 关闭摄像头和音频
      // tracks.forEach(track => {
      //   track.stop();
      // });

      // tracks2.forEach(track => {
      //   track.stop();
      // });
    },

    // 有色彩的
    gotStream(stream) {
      console.log(2)
      window.stream = stream; // make stream available to console
      this.$refs.camera.srcObject = stream;

      // 使用 tracking.js 在图像上框选出人脸
      // var canvas = document.getElementById('canvas');
      // this.drawBox(canvas, this.$refs.video);

    },
    // 没色彩的
    gotStream1(stream) {
      console.log(1)
      window.stream = stream; // make stream available to console
      this.$refs.camera1.srcObject = stream;

      // 相机2
      // var canvas1 = document.getElementById('canvas1');
      // this.drawBox(canvas1, videoElement1);
    },
    handleError(error) {
      console.log('Error: ', error);
    },
    // base64转blob
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      var theBlob = new Blob([u8arr], { type: mime });
      theBlob.lastModifiedDate = new Date();
      theBlob.name = filename;
      return theBlob;
    },

    // 上传拍照信息
    verification() {
      const loading = this.$loading({
        lock: true,
        text: '稽核中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      const formData = new FormData();
      formData.append("sourcePhotoColor", this.headBlob);
      formData.append("sourcePhotoSecond", this.headBlob2);


      axios.post(GLOBAL.BASE_URL + "h5/binocular_photo/upload", formData, {
        headers: {
          "Content-type": "multipart/form-data",
          "Authorization": "Bearer " + this.acctoken,
        }
      })
        .then(
          (res) => {
            loading.close();
            if (res.data.code == 0) {
              window.location.href = this.backUrl + '?orderNo=' + this.orderNo
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            }
          },
          (err) => {
            // 出现错误时的处理
            this.$message({
              message: err.msg,
              type: "error",
            });
          }
        );
    },

    // -------------------------------通用方法--------------------------------
    // 清除视频认证的方式
    clearVideo() {
      this.isopen = false;

      this.uploadImg = null;  //是否上传图片

      this.idphoto = null;  //证件照
    },
    // 启动h5活体检测
    startLive() {
      var object = {
        nonce: "2576386758shbgkcfvth27549kfgcb32",
        orderNo: 'efa1ab235a174920ad3d86f36e62b1e7',
        url: encodeURI('https://www.baidu.com/'),
        sign: '435969EFBC9AE853279048637223C63C597DDF37',
        version: "1.0.0",
        resultType: 1,
        userId: 'abc',
        webankAppId: "abcd",
      };
      var title = "";

      for (let key in object) {
        title += key + "=" + object[key] + "&";
      }



      window.location.href = "http://192.168.1.169:7900/api/pc/livelogin?" + title
    },
  },
};
</script>

<style scoped>
.authentication_all {
  padding: 34px 0 0 82px;
  box-sizing: border-box;
}

.authentication {
  display: flex;
  align-items: flex-start;
  font-size: 20px;
  margin-bottom: 40px;
}

.authentication_title {
  display: flex;
  align-items: center;
  font-size: 24px;
  color: #333333;
  margin-bottom: 10px;
}

.camera {
  width: 682px;
  height: 421px;
}

.k {
  margin-right: 60px;
  cursor: pointer;
}

.selectmode {
  color: #3371FF;
}

.selectcamera {
  display: flex;
  align-items: center;
}

.authentication_open {
  background: url('../assets/button.png');
  background-size: 100% 100%;
  width: 143px;
  height: 47px;
  cursor: pointer;
  line-height: 47px;
  text-align: center;
  font-size: 20px;
  color: white;
}

.authentication_head {
  width: 120px;
  height: 120px;
  background: #F5F5F5;
}

.binocular_photography {
  display: flex;
  margin-bottom: 15px;
  justify-content: space-between;
  width: 1419px;
}

.norecord {
  width: 120px;
  height: 120px;
}

.authentication_upload {
  background: url("../assets/upload.png");
  background-size: 100% 100%;
  width: 120px;
  height: 120px;
}

.authentication_tips {
  width: 170px;
}

.authentication_submit {
  background: url('../assets/button.png');
  background-size: 100% 100%;
  width: 180px;
  height: 62px;
  cursor: pointer;
  line-height: 62px;
  text-align: center;
  font-size: 24px;
  color: white;
  margin: 0 auto;
}
</style>