<template>
   <div>
      <iframe :src="url" frameborder="0" allow="microphone;camera;midi;encrypted-media;" class="ifame"></iframe>
   </div>
</template>

<script>
export default {
  data(){
    return{
       url:'',  //ifame地址
    }
  },

  created(){
    var token = this.$route.query.token;
    this.url = 'https://api.megvii.com/faceid/liveness/v2/do?token=' + token
  }
}
</script>

<style  scoped>
.ifame{
    width: 100%;
    min-height: 800px;
}
</style>