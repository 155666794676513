<template>
  <div>
    <el-input v-model="sn" placeholder="请输入SN号" class="sn"></el-input>
    <div class="identify">
      <div class="identify_type" @click="ididentification(0)">身份证识别</div>
      <div class="identify_type" @click="ididentification(1)">网证识别</div>
    </div>
    <div class="result">
      <div class="result_single">
        <img src="@/assets/icon1.png" class="result_title">
        <div class="result_content">{{ identifyInfo.name }}</div>
      </div>
      <div class="result_single">
        <img src="@/assets/icon2.png" class="result_title">
        <div class="result_content">{{ identifyInfo.idNo }}</div>
      </div>
      <div class="result_single">
        <img src="@/assets/icon3.png" class="result_title">
        <div class="result_content">{{ identifyInfo.phone }}</div>
      </div>
      <div class="result_single">
        <img src="@/assets/icon4.png" class="result_title">
        <div class="result_content">{{ identifyInfo.gender }}</div>
      </div>
      <div class="result_single">
        <img src="@/assets/icon5.png" class="result_title">
        <div class="result_content">{{ identifyInfo.birth }}</div>
      </div>
      <div class="result_single">
        <img src="@/assets/icon6.png" class="result_title">
        <div class="result_content">{{ identifyInfo.address }}</div>
      </div>
      <div class="result_single">
        <img src="@/assets/icon7.png" class="result_title">
        <div class="result_content">{{ identifyInfo.issue }}</div>
      </div>
      <div class="result_single">
        <img src="@/assets/icon8.png" class="result_title">
        <div class="result_content">{{ identifyInfo.sn }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import http from "../utils/http";
  import sha1 from "sha1";
  import mqtt from "mqtt/dist/mqtt";
  export default {
    data() {
      return {
        identifyInfo: {}, //人脸检测信息
        sn:'',  //sn号
        userid:'mzx'
      };
    },

    created() {
      // 连接mqtt
      this.initMqtt();
    },

    methods: {
      // 初始化mqtt
      initMqtt() {
        var code = "";
        //设置长度，这里看需求，我这里设置了4
        var codeLength = 4;

        //设置随机字符
        var random = new Array(0, 1, 2, 3, 4, 5, 6, 7, 8, 9);

        //循环codeLength 我设置的4就是循环4次
        for (var i = 0; i < codeLength; i++) {
          //设置随机数范围,这设置为0 ~ 36
          var index = Math.floor(Math.random() * 9);

          //字符串拼接 将每次随机的字符 进行拼接
          code += random[index];
        }
        this.client = mqtt.connect("wss://web.face.third.srthinker.com/mqtt", {
          // ws这个为连接类型，还有wss 为加密型
          connectTimeout: 40000,
          clientId: "fegregds" + code,
          username: "",
          password: "",
          clean: true,
        });

        this.client.on("connect", (e) => {
          console.log("连接成功！！！", e);
          this.client.subscribe(["FACE-WEB-mzx"], { qos: 0 }, (error) => {
            // qos 为通道
            if (!error) {
              console.log("订阅成功");
            } else {
              console.log("订阅失败");
            }
          });
        });
        // 接收消息处理
        this.client.on("message", (topic, res) => {
          // console.log('收到来自', topic, '的消息', JSON.stringify(res))
          const resData = JSON.parse(res.toString());

          // 处理返回来的数据
          console.log(resData);
          this.identifyInfo = resData;
        });
        // 关闭
        // client.end()
      },

      // 发送身份证识别命令
      ididentification(type) {
        if (this.sn == "") {
          this.$message.error("请先输入sn号");
          return;
        }
        var topic = "FACE-HARD-" + this.sn;
        if (!this.client.connected) {
          console.log("客户端未连接");
          return;
        }
        this.client.publish(
          topic,
          JSON.stringify({ action: "check", type: type, user: this.userid }),
          { qos: 1 },
          (err) => {
            if (!err) {
              console.log("主题为" + topic + "发布成功");
            }
          }
        );
      },
    },
  };
</script>

<style scoped>
  .sn {
    width: 741px;
    height: 48px;
    margin: 100px auto 0;
  }

  .identify {
    width: 741px;
    display: flex;
    align-items: center;
    margin: 20px auto 0;
  }

  .identify_type {
    width: 140px;
    height: 44px;
    background-size: 100% 100%;
    font-size: 22px;
    margin-right: 30px;
    color: white;
    line-height: 44px;
    cursor: pointer;
    text-align: center;
    background: url("/src/assets/button2.png");
  }

  .result {
    width: 741px;
    margin: 60px auto 0;
  }

  .result_single {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .result_title {
    width: 110px;
    height: 48px;
  }

  .result_content {
    width: 603px;
    height: 48px;
    border: 1px solid #C1C9ED;
    padding-left: 24px;
    line-height: 48px;
    text-align: left;
    box-sizing: border-box;
    font-size: 22px;
    color: #000000;
    margin-left: 28px;
  }
</style>