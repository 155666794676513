/****   http.js   ****/
// 导入封装好的axios实例
import request from "./request";
import GLOBAL from "../utils/common";
const baseURL = GLOBAL.BASE_URL;

const http = {
  /**
   * methods: 请求
   * @param url 请求地址
   * @param params 请求参数
   */
  get(url, params, header) {
    const config = {
      method: "get",
      url: baseURL + url,
    };
    if (params) config.params = params;
    if (header) config.headers = header;
    return request(config);
  },
  post(url, params, header) {
    const config = {
      method: "post",
      url: baseURL + url,
    };
    if (params) config.data = params;
    if (header) config.headers = header;
    return request(config);
  },
  put(url, params) {
    const config = {
      method: "put",
      url: baseURL + url,
    };
    if (params) config.params = params;
    return request(config);
  },
  delete(url, params) {
    const config = {
      method: "delete",
      url: baseURL + url,
    };
    if (params) config.params = params;
    return request(config);
  },
};
//导出
export default http;
