<template>
  <div>
    <div class="title">智能自闭阀</div>
    <div class="gas_operate">
      <div>操作:</div>
      <div class="gas_operate_c">
        <el-button
          type="primary"
          :loading="isload"
          @click="onoff(true)"
          :disabled="isOperate"
          >开阀</el-button
        >
        <el-button
          type="primary"
          :loading="isload3"
          @click="onoff(false)"
          :disabled="isOperate"
          >闭阀</el-button
        >
        <el-button
          type="primary"
          :loading="isload2"
          @click="setTime"
          :disabled="isOperate"
          >设置时间</el-button
        >
        <div class="gas_top_select">
          <div>调节压强档:</div>
          <el-select
            v-model="value"
            :disabled="isload4 || isOperate"
            placeholder="请选择"
            @change="selectposition"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="gas_status">
      <div class="gas_status_top">
        <div class="gas_status_title">设备运行状态:</div>
        <!-- <el-button
          type="primary"
          class="gas_status_button"
          @click="getDevice($event)"
          >刷新状态</el-button
        > -->
      </div>
      <div
        class="gas_status_content"
        v-for="(value, key, index) in detail"
        :key="index"
      >
        <div class="gas_status_content_q">{{ mapping(key) }}:</div>
        <div>{{ value }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CryptoJS from "crypto-js";
import http from "../utils/http";
import mqtt from "mqtt/dist/mqtt";
export default {
  data() {
    return {
      TimeStamp: "", //时间戳
      HmacSHA1: "", //加密
      detail: [], //具体信息
      token: "", //token
      equipmentName: "", //设备名称
      productKey: "", //设备key
      isload: false,
      isload2: false,
      isload4: false,
      client: null,
      isload3: false,
      isOperate: true,
      value: "",
      options: [
        {
          value: "1",
          label: "1",
        },
        {
          value: "2",
          label: "2",
        },
        {
          value: "3",
          label: "3",
        },
        {
          value: "4",
          label: "4",
        },
        {
          value: "5",
          label: "5",
        },
        {
          value: "6",
          label: "6",
        },
        {
          value: "7",
          label: "7",
        },
        {
          value: "8",
          label: "8",
        },
        {
          value: "9",
          label: "9",
        },
        {
          value: "10",
          label: "10",
        },
      ],
    };
  },

  created() {
    // this.login();

    this.initMqtt();
  },

  methods: {
    // 默认登录
    login() {
      var that = this;
      axios
        .post("/sys/dev/user/login", {
          autoLogin: true,
          userLogin: "sixiang",
          userPassword: "qwe123",
        })
        .then(function (response) {
          var data = response.data;
          if (data.code == 200) {
            // HmacSHA1 加密
            let TimeStamp = new Date().getTime();
            let token = data.data.token;
            // console.log(token);
            let src = `token=${token}&&timeStamp=${TimeStamp}`;
            let HmacSHA1 = CryptoJS.HmacSHA1(
              src,
              "90d2fca50ea8ed5472c5776c9fc53638"
            ).toString();

            that.TimeStamp = TimeStamp;
            that.HmacSHA1 = HmacSHA1;

            that.token = data.data.token;

            that.getDevice();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    // 初始化mqtt
    initMqtt() {
      let TimeStamp = new Date().getTime();
      var that = this;
      that.client = mqtt.connect("wss://web.face.third.srthinker.com/mqtt", {
        // ws这个为连接类型，还有wss 为加密型
        connectTimeout: 40000,
        clientId: TimeStamp,
        username: "ijkl",
        password: "9101112",
        clean: true,
      });

      that.client.on("connect", (e) => {
        console.log("连接成功！！！", e);
        this.client.subscribe(
          [
            "topic/iot/sys/NK3O6VJ4HP3944695/V14D5U-YXJVIR/prop",
            "topic/iot/sys/NK3O6VJ4HP3944695/V14D5U-YXJVIR/message/reply",
          ],
          { qos: 0 },
          (error) => {
            // qos 为通道
            if (!error) {
              console.log("订阅成功");
            } else {
              console.log("订阅失败");
            }
          }
        );
      });
      // 接收消息处理
      that.client.on("message", (topic, res) => {
        console.log("收到来自", topic, "的消息");

        if (topic == "topic/iot/sys/NK3O6VJ4HP3944695/V14D5U-YXJVIR/prop") {
          this.isOperate = false;
          const decoder = new TextDecoder();
          const str = decoder.decode(res);

          var object = JSON.parse(str);
          this.detail = object;
        } else {
          this.isload = false;
          this.isload4 = false;
          this.isload2 = false;
          this.isload3 = false;
        }
      });
      // 关闭
      // client.end()
    },

    getDevice(event) {
      var that = this;
      if (event.target.nodeName === "SPAN") {
        event.target.parentNode.blur();
      } else {
        event.target.blur();
      }
      axios({
        method: "post",
        url: "/sys/dev/product/equipment/query",
        headers: {
          Authorization: "Bearer " + that.token,
          Sign: that.HmacSHA1,
          Timestamp: that.TimeStamp,
        },
        data: {
          equipmentId: "1830381668209262592",
        },
      }).then((res) => {
        if (res.data.code == 200) {
          that.equipmentName = res.data.data.equipmentName;
          that.productKey = res.data.data.productKey;

          that.getDeviceInfo();
        } else {
          this.$message.error(res.data.message);
        }
      });
    },

    // 获取设备
    getDeviceInfo() {
      var that = this;
      axios({
        method: "post",
        url: "/sys/dev/device/runtime",
        headers: {
          Authorization: "Bearer " + that.token,
          Sign: that.HmacSHA1,
          Timestamp: that.TimeStamp,
        },
        data: {
          deviceName: that.equipmentName,
          productKey: that.productKey,
          type: "prop",
        },
      }).then((res) => {
        if (res.data.code == 200) {
          that.detail = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },

    // 开关阀门
    onoff(value) {
      if (value) {
        this.isload = true;
      } else {
        this.isload3 = true;
      }
      let TimeStamp = new Date().getTime();
      var that = this;
      if (event.target.nodeName === "SPAN") {
        event.target.parentNode.blur();
      } else {
        event.target.blur();
      }
      this.client.publish(
        "topic/iot/sys/NK3O6VJ4HP3944695/V14D5U-YXJVIR/service/invoke",
        JSON.stringify({
          body: value,
          deviceCode: "V14D5U-YXJVIR",
          identifier: "status_open_controll",
          messageId: "1837603624931295232",
          productCode: "NK3O6VJ4HP3944695",
          timestamp: TimeStamp,
        }),
        { qos: 0 },
        (err) => {
          if (!err) {
            that.$message.success("操作成功");
          }
        }
      );

      return;
      if (value) {
        this.isload = true;
      } else {
        this.isload3 = true;
      }
      var data = {
        command: {
          status_open_controll: value,
        },
        deviceName: this.equipmentName,
        functionName: "自闭状态控制",
        identifier: "status_open_controll",
        physicalServiceId: "1830404466801639424",
        productKey: this.productKey,
      };

      this.escalation(data);
    },

    // 设置时间
    setTime() {
      var that = this;
      that.isload2 = true;
      if (event.target.nodeName === "SPAN") {
        event.target.parentNode.blur();
      } else {
        event.target.blur();
      }
      let TimeStamp = new Date().getTime();
      this.client.publish(
        "topic/iot/sys/NK3O6VJ4HP3944695/V14D5U-YXJVIR/service/invoke",
        JSON.stringify({
          body: TimeStamp,
          deviceCode: "V14D5U-YXJVIR",
          identifier: "time_set",
          messageId: "1833311769406210048",
          productCode: "NK3O6VJ4HP3944695",
          timestamp: TimeStamp,
        }),
        { qos: 0 },
        (err) => {
          if (!err) {
            that.$message.success("操作成功");
          }
        }
      );
    },

    // 调节档位
    selectposition(value) {
      var that = this;
      that.isload4 = true;
      let TimeStamp = new Date().getTime();
      if (event.target.nodeName === "SPAN") {
        event.target.parentNode.blur();
      } else {
        event.target.blur();
      }
      that.client.publish(
        "topic/iot/sys/NK3O6VJ4HP3944695/V14D5U-YXJVIR/service/invoke",
        JSON.stringify({
          body: value,
          deviceCode: "V14D5U-YXJVIR",
          identifier: "pressure_range_controll",
          messageId: "1833311683808854016",
          productCode: "NK3O6VJ4HP3944695",
          timestamp: TimeStamp,
        }),
        { qos: 0 },
        (err) => {
          if (!err) {
            that.$message.success("操作成功");
          }
        }
      );
    },

    // 上报操作
    escalation(data) {
      var that = this;
      axios({
        method: "post",
        url: "/sys/dev/device/server/invokeWithReply",
        headers: {
          Authorization: "Bearer " + that.token,
          Sign: that.HmacSHA1,
          Timestamp: that.TimeStamp,
        },
        data: data,
      }).then((res) => {
        this.isload = false;
        this.isload2 = false;
        this.isload3 = false;
        if (res.data.code == 200) {
          if (res.data.data.code == 4025) {
            that.$message.warning(res.data.data.msg);
          } else {
            that.$message.success(res.data.data.message);
          }
        } else {
          that.$message.error(res.data.message);
        }
      });
    },

    // 映射字段
    mapping(value) {
      switch (value) {
        case "sn":
          return "编号";
        case "device_address":
          return "设备地址";
        case "voltage":
          return "电池电压";
        case "status_online":
          return "设备状态";
        case "status_open":
          return "自闭状态";
        case "pressure_in":
          return "气压（进）";
        case "pressure_out":
          return "气压（出）";
        case "temperature_in":
          return "温度（进）";
        case "temperature_out":
          return "温度（出）";
        case "alert":
          return "告警";
        case "log":
          return "日志";
        case "pressure_range":
          return "压强档";
      }
    },
  },
};
</script>

<style scoped>
.title {
  text-align: center;
  font-size: 30px;
  margin-top: 50px;
}

.gas_top {
  display: flex;
  margin-top: 20px;
  align-items: center;
}

.gas_top div {
  margin-left: 30px;
  font-size: 26px;
}

.gas_operate {
  margin-top: 20px;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 26px;
  box-sizing: border-box;
}

.gas_status {
  padding-left: 30px;
  box-sizing: border-box;
}

.gas_status_title {
  font-size: 26px;
}

.gas_status_content {
  margin-top: 20px;
  font-size: 24px;
  display: flex;
  align-items: center;
}

.gas_status_content_q {
  width: 150px;
  margin-right: 20px;
  text-align: left;
}

.gas_top_select {
  display: flex;
  align-items: center;
  margin-left: 30px;
}

.gas_status_top {
  display: flex;
  margin-top: 20px;
  align-items: center;
}

.gas_status_button {
  width: 100px;
  margin-left: 20px;
}
.gas_operate_c {
  display: flex;
  align-items: center;
}
</style>